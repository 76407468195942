*{
  -moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Kanit', sans-serif !important; 
  padding-right: 0 !important;
  padding: 0 !important;
}

 label.Mui-focused {
  color: white;
}