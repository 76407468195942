/* .banner {

  max-width: 100%;
  height: auto;
} */
.banner {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 999;
}

.background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: -1;
}

h1 {
  color: #333;
  font-family: tahoma;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 550px;
}

h1 span {
  font-size: 40px;
  margin-left: 40px;
}

.Typewriter {
  color: #000 !important;
  display: block;
  font-weight: 900;
  font-size: 3rem;
  position: relative;
  font-family: 'Kanit', sans-serif !important; 
}

.message {
  /* width: 280px !important; */
  background-color: grey ;
  color: #000;
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
  font-size: 3rem;
  margin: 0 20px;
  top: 0;
  padding-left: 10px;
  left: 130px;
  animation: openclose 8s ease-in-out infinite;
}

.word1,
.word2,
.word3 {
  padding: 0 20px;
}

@keyframes openclose {
  0% {
    top: 0.2rem;
    width: 0;
  }

  5% {
    width: 0;
  }

  15% {
    width: 280px;
  }

  30% {
    top: 0.2rem;
    width: 280px;
  }

  33% {
    top: 0.2rem;
    width: 0;
  }

  35% {
    top: 0.2rem;
    width: 0;
  }

  38% {
    top: -4.5rem;

  }

  48% {
    top: -4.5rem;
    width: 280px;
  }

  62% {
    top: -4.5rem;
    width: 280px;
  }

  66% {
    top: -4.5rem;
    width: 0;
    text-indent: 0;
  }

  71% {
    top: -11rem;
    width: 0;
    text-indent: 5px;
  }

  86% {
    top: -11rem;
    width: 285px;
  }

  95% {
    top: -11rem;
    width: 280px;
  }

  98% {
    top: -11rem;
    width: 0;
  }

  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}

/* @media only screen and (min-width: 960px) {
  .banner
  {
    width: 960px;
    height: auto;
  }
}
@media only screen and (min-width: 1440px) {
  .banner
  {
    width: 1440px;
    height: auto;
  }
}
@media only screen and (min-width: 2000px) {
  .banner
  {
    width: 2000px;
    height: auto;
  }
}
@media only screen and (max-device-width: 480px) {
 .banner
  {
    width: 480px;
    height: auto;
  }
}
@media only screen and (device-width: 768px) {
 .banner
  {
    width: 768px;
    height: auto;
  }
} */
.bannerIcon {
  width: 50px;
  height: 50px;
  border-radius: 30%;
}

.chiclets {
  --chiclet-size: 96px;
  display: grid;
  grid-template-rows: repeat(5, 96px);
  grid-template-columns: repeat(19, 96px);
  grid-gap: 22px;
  justify-content: center;
}

.background span {
  position: absolute;
  width: 60px;
  height: 60px;
  background: #000;
  box-shadow: 0px 0px 10px #000,
    0px 0px 40px #000,
    0px 0px 80px #000,
    0px 0px 120px #000,
    0px 0px 200px #000;
  border-radius: 30%;
  outline: none;

}

.background span:nth-child(n+1) {
  background: transparent;
  border: 5px solid #000;
  animation: animate2 5s linear infinite;

}

@keyframes animate2 {
  100% {
    border: 5px solid #000;
    background: #000
  }
}

.background span:nth-child(1) {
  top: 80%;
  left: 20%;
  animation: animate 10s linear infinite;
}

.background span:nth-child(2) {
  top: 80%;
  left: 60%;
  animation: animate 18s linear infinite;
}

.background span:nth-child(3) {
  top: 80%;
  left: 80%;
  animation: animate 25s linear infinite;
}

.background span:nth-child(4) {
  top: 80%;
  left: 40%;
  animation: animate 14s linear infinite;
}

.background span:nth-child(5) {
  top: 80%;
  left: 30%;
  animation: animate 17s linear infinite;
}

.background span:nth-child(6) {
  top: 80%;
  left: 60%;
  animation: animate 6s linear infinite;
}

.background span:nth-child(7) {
  top: 80%;
  left: 25%;
  animation: animate 14s linear infinite;
}

.background span:nth-child(8) {
  top: 80%;
  left: 77%;
  animation: animate 14s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0);

  }

  50% {
    transform: rotateZ(180) translateX(-50px);
  }

  100% {
    transform: scale(1) translateY(-500px) rotateZ(360deg) translateX(80px);
    z-index: 1000;

  }
}

.bannerText {
  width: 100%;
  text-align: center;
  padding: 286px 0;
}

.content {
  height: 200px;
  text-align: center;
  overflow: hidden;
  font-size: 35px;
  margin: 0 auto;
  line-height: 40px;
  color: #ecf0f1;
  width: 700px;
}

.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 300px;
  padding: 0 40px;
}

.bText {
  width: 100%;
  display: flex;
  padding: 180px 0 0;
  justify-content: center;
  text-align: center;
}

.bHtext1:before {
  content: '[';
  left: 0;
}

.bHtext2:after {
  content: ']';
  position: absolute;
  right: 0;
}

.bHtext2:after,
.bHtext1:before {
  position: absolute;
  top: 23%;
  color: #000;

  font-size: 5rem;
  line-height: 40px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.bHtext1 {
  font-family: 'Kanit', sans-serif;
  float: left;
  margin: 0;
  position: relative;

}

.bHtext1::after {
  content: '';
  color: #000;
  font-family: 'Kanit', sans-serif;
  font-size: 4rem;
  font-weight: 800;
  letter-spacing: 35px;
  margin: 0 30px 0 57px;
  transition: all 0.50s linear;
  -webkit-animation-name: profile;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: profile;
  animation-duration: 10s;
  animation-delay: 2s;
  animation-iteration-count: infinite;

  display: inline-block;
}

@keyframes profile {
  0% {
    content: 'We';
    transition: all 0.50s linear;
  }

  50% {
    content: 'We';
    transition: all 0.50s linear;
  }

  100% {
    content: 'You';
    transition: all 0.50s linear;
  }
}

.bHtext2 {
  float: left;
  margin: 0;
  position: relative;
}

.bHtext2::before {
  content: '';
  color: #000;
  font-family: 'Kanit', sans-serif;
  font-size: 4rem;
  transition: all 0.50s linear;
  font-weight: 800;
  margin: 0 5px;
  margin: 0 57px 0 30px;
  letter-spacing: 35px;
  -webkit-animation-name: list;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: list;
  animation-duration: 10s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  display: inline-block;
  font-size: 4rem;
}

@keyframes list {
  0% {
    content: 'Develop';
  }

  50% {
    content: 'Create';
    transition: all 0.50s linear;
  }

  100% {
    content: 'Play';
    transition: all 0.50s linear;
  }
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}


@-o-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}