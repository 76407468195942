

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.adminBody{
  height: 100vh;
}

.preloaderp {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width:100vw;
  margin: auto;
  background-color: #fff;
}

.preloaderp .css-215w7l {
  /* position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; */
}

.preLeoderL1 {
  height: 150px;
  width: 150px;
  /* opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  
  /* margin: auto; */
  /* border-width: thick;
  border-style: solid;
  border-color: rgb(54, 215, 183);
  border-image: initial;
  border-radius: 50%; */
  animation: 2s cubic-bezier(0.165, 0.84, 0.44, 1) -1s infinite normal none running animation-7w6l1j, cubic-bezier(0.3, 0.61, 0.355, 1) normal none running animation-66508u;
}



.preLeoderL2 {
  /* position: absolute; */
  height: 150px;
  width: 150px;
  /* opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */

  margin: auto;
  /* border-width: thick;
  border-style: solid;
  border-color: rgb(54, 215, 183);
  border-image: initial;
  border-radius: 50%; */
  animation: 2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s infinite normal none running animation-7w6l1j, cubic-bezier(0.3, 0.61, 0.355, 1) normal none running animation-66508u;
}

@keyframes animation-7w6l1j {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(0.5);
  }
}

@keyframes animation-66508u {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}



.css-5ir5xx-MuiLinearProgress-bar1 {
  background-color: black !important;
  }
  .css-1r8wrcl-MuiLinearProgress-bar2{
      background-color: black !important;
  }
  .css-eglki6-MuiLinearProgress-root {
    background-color: #000;
      height: 2px !important;
      position: fixed !important;
      top: 0 !important;
      width: 100% !important;
      z-index: 99999 !important;
      left: 0 !important;
  }
  .progessLinear {
    background-color: #000;
      height: 2px !important;
      position: fixed !important;
      top: 0 !important;
      width: 100% !important;
      z-index: 99999 !important;
      left: 0 !important;
  }