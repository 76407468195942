body{
    position: relative;
}
.css-5ir5xx-MuiLinearProgress-bar1 {
background-color: black !important;
}
.css-1r8wrcl-MuiLinearProgress-bar2{
    background-color: black !important;
}
.css-eglki6-MuiLinearProgress-root {
    height: 2px !important;
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    z-index: 99999 !important;
    left: 0 !important;
    background-color: #000 ;
}
